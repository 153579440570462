import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='PC Eats | François Deguire' />
    <ProjectPage
      name='PC Eats'
      description={`
      President’s Choice Eats is a fictional digital application that 
      explores the unifying Loblaws’ PC express online shopping, 
      store flyers and recipe planning into one single interface and app. 
      The user can plan meals weekly with recipe recommendations based on 
      the current sales at his/hers preferred Loblaws’ subsidiary food stores. 
      With a single tap or click, the user can add all of the necessary 
      ingredients to his shopping list to make the process of meal planning 
      and grocery shopping easier. The user can then follow a recipe assistant 
      to help him/her cook the recipe successfully.
      `}
      meta={`
        Personal project<br>
        In 2020
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    pceats1: file(relativePath: { eq: "pceats/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pceats2: file(relativePath: { eq: "pceats/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pceats3: file(relativePath: { eq: "pceats/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pceats4: file(relativePath: { eq: "pceats/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pceats5: file(relativePath: { eq: "pceats/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
